// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faExternalLinkAlt as fasFaExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons/faExternalLinkAlt";

import { faFacebookF as fabFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter as fabFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faInstagram as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faYoutube as fabFaYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add solid icons
 */
library.add(fasFaTimes, fasFaExternalLinkAlt);


/**
 * Add social icons
 */
library.add(fabFaFacebookF, fabFaTwitter, fabFaInstagram, fabFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
